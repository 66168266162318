

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen {
  .pdf-container {
    position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
}

::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}

::-webkit-scrollbar-thumb:hover{
  background: rgba(0,0,0,0.4);
}

::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.9);
}

.active {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.hidden {
  display: none;
  position: relative;
}

p {
  margin: 4px;
}

.mantine-Table-root td .mantine-Text-root {
  min-width: 100%;
}

.Timeline-Collection {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid #dedee7;
  border-right: 0.05rem solid #f7f7f7;
  background-color: #f3f4f6;
}

.Timeline-Cell {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 0.1rem solid #dedee7;
  border-right: 0.05rem solid #f7f7f7;
}

.Timeline-Cell-Left {
  color: #495057;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.1rem solid #dedee7;
  border-right: 0.05rem solid #f7f7f7;
  background-color: #ffffff;
}

.Timeline-Cell-Header {
  color: #495057;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  border-bottom: 0.0625rem solid rgb(222, 226, 230);
}

.Timeline-Cell-Header-Right {
  color: #495057;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;

  border-right: 0.0625rem solid rgb(222, 226, 230);
  border-bottom: 0.0625rem solid rgb(222, 226, 230);
}

.Timeline-Event-1 {
  background: #9ea0a2;
  height: 16px;
  width: 26px;
  position: absolute;
  cursor: pointer;
}

.Timeline-Event-2 {
  background: #6799c7;
  height: 16px;
  width: 26px;
  position: absolute;
  cursor: pointer;
}

.Timeline-Event-3 {
  background: #8ccc7b;
  height: 16px;
  width: 26px;
  position: absolute;
  cursor: pointer;
}

.Timeline-Event-4 {
  background: #ffa1a1;
  height: 16px;
  width: 26px;
  position: absolute;
  cursor: pointer;
}

.Timeline-Event-5 {
  background: #ffe67f;
  height: 16px;
  width: 26px;
  position: absolute;
  cursor: pointer;
}

.Timeline-Start-Event {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 16px;
}

.Timeline-End-Event {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-right: 16px;
}

.Timeline-Full-Event {
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 16px;
  width: calc(100% - 7px);
}

.Timeline-Cell-Highlight {
  background-color: #f3f4f6;
}

.Timeline-Cell-Month {
  border-right: 0.0625rem solid rgb(222, 226, 230);
}

.Timeline-Cell-Today {
  border-right: 0.1rem solid #185e9f !important;
  background-color: #e4f1ff;

}

div:has(+ .Timeline-Cell-Today) {
  border-right: 0.1rem solid #185e9f !important;
}

.Timeline-Cell-Error {
  background-color: #ffebee;
  border-right-color: #ffebee !important;
}

.Timeline-Cell-Success {
  background-color: #e8f5e9;
  border-right-color: #e8f5e9 !important;
}

.Timeline-Cell-Project {
  background-color: aliceblue !important;
  font-weight: 600;
}

.Dark-Theme .Timeline-Cell-Project {
  background-color: #01111e !important;
  font-weight: 600;
}

.Timeline-Cell-Last-Sticky {
  border-right: 0.1rem solid #e8eaee !important;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid #dedee7;
}

.Dark-Theme .Timeline-Collection {
  border-color: #373A40 !important;
  border-right-color: #1f2125 !important;
  background-color: #151414;
}

.Dark-Theme .Timeline-Cell {
  border-color: #373A40 !important;
  border-right-color: #1f2125 !important;
}

.Dark-Theme .Timeline-Cell-Left {
  color: #C1C2C5;
  border-bottom: 0.1rem solid #373A40;
  border-right: 0.05rem solid #1a1b1e;
  background-color: #1a1b1e;
}

.Dark-Theme .Timeline-Cell-Header {
  color: #C1C2C5 !important;
  border-color: #373A40 !important;
  background-color: #151414;
}

.Dark-Theme .Timeline-Cell-Header-Right {
  color: #C1C2C5 !important;
  border-color: #373A40 !important;
  background-color: #151414;
}

.Dark-Theme .Timeline-Cell-Highlight {
  background-color: #151414;
}

.Dark-Theme .Timeline-Cell-Month {
  border-right: 0.0625rem solid #373A40 !important;
}

.Dark-Theme .Timeline-Cell-Today {
  background-color: #0f2a46;
}

.Dark-Theme .Timeline-Cell-Error {
  background-color: #2c0606 !important;
  border-right-color: #2c0606 !important;
}

.Dark-Theme .Timeline-Cell-Success {
  background-color: #041e04 !important;
  border-right-color: #041e04 !important;
}

.Dark-Theme .Timeline-Cell-Last-Sticky {
  border-right: 0.1rem solid #373A40 !important;
  border-bottom: 0.1rem solid #373A40;
}

.ReactVirtualized__Grid::-webkit-scrollbar {
  display: none;
}

div:nth-child(2).ReactVirtualized__Grid::-webkit-scrollbar {
  display: block;
}

* {
  zoom: unset;
}

.page-breaker {
  break-before: page;
}

.image-resizer {
  display: inline-flex;
  position: relative;
  flex-grow: 0;
}

.image-resizer .resize-trigger-x {
  position: absolute;
  right: -20px;
  bottom: 50%;
  opacity: 0;
  transition: opacity .3s ease;
  color: #3259a5;
  cursor: w-resize;
}

.image-resizer:hover .resize-trigger-x {
  opacity: 1;
}

.image-resizer .resize-trigger-y {
  position: absolute;
  right: 50%;
  bottom: -12px;
  opacity: 0;
  transition: opacity .3s ease;
  color: #3259a5;
  cursor: n-resize;
}

.image-resizer:hover .resize-trigger-y {
  opacity: 1;
}

/* Print progressive page numbers */
.page-number:before {
  content: "Page: " counter(pageIndex);
  counter-increment: pageIndex +1;
}

.content {
  width: 1640px;
}

.header,
.header-space,
.footer,
.footer-space {
  height: 80px;
  min-height: 80px;
  max-height: 80px;
}

.header {
  position: fixed;
  top: 0;
  width: 1640px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: grey;
  margin-top: 8px;
  margin-bottom: 8px;
}

@page {
  margin: 0.2cm;
  margin-bottom: 1.25cm;
}

.blinking {
  animation: blinker 1s infinite;
}

@keyframes blinker {
  from {
    opacity: 1.0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1.0;
  }
}

.mantine-Card-root {
  break-inside: avoid;
}

@page {
  size: A4 landscape !important;
}

.grayscaleBasemap {
  filter: brightness(0.6) grayscale(1) blur(2px);
}

.vividBasemap {
  filter: brightness(1.2) contrast(1.2) drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.4));
}

.leaflet-tooltip {
  background: transparent !Important;
  color: white !important;
  font-size: 16px !important;
  text-shadow:
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000; /* Sombra do texto simulando uma borda */
  border: none !Important;
  box-shadow: none !Important;
}

.fullScreenHeight {
  height: 100%;
}